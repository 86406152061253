import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';
import { MESSAGES } from '@shared/constants';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styles: ``
})
export class UnauthorizedAccessComponent {

  MESSAGES = MESSAGES;

  constructor(private authService: AuthenticationService,
              private r: Router) { }

  goToLogin(): void {
    this.authService.logout()
      .pipe(finalize(() => this.r.navigateByUrl('/login'))).subscribe();
  }

}
