import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MESSAGES } from '@shared/constants';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styles: ``
})
export class PageNotFoundComponent {

  MESSAGES = MESSAGES;

  constructor(private r: Router) { }

  back(): void {
    this.r.navigateByUrl('/');
  }

}
